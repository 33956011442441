/* eslint-disable react/no-unstable-nested-components */
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import WalletHolderTabMenu from "components/users/Menu";
import WhDetailHeader, {
  DangerIcon,
} from "components/wallet-holder/detail/WhDetailHeader";
import { IColumnProps } from "interfaces/mui-column";
import {
  CARD_STATUS,
  IWalletHolderCardProps,
  IWalletHolderProps,
} from "interfaces/wallet-holder";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { archiveCard, getAllCardsForUser } from "utils/apiProvider";

import { AxiosError } from "axios";
import { showToast } from "redux/toast/action";
import { style } from "themes/modal";
import { UTCLocalTimeStampCompact } from "components/shared/UTCLocalTimestampComponent";
import { PrintCardButton } from "components/wallet-holder/ActionButtons";
import colors from "constants/colors";

const WalletHolderCardList = () => {
  const params = useParams<{ id: string; type: string }>();
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState<IWalletHolderCardProps[]>([]);
  const [selectedCard, setSelectedCard] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const { state }: IWalletHolderProps | any = useLocation();

  const fetchCardList = async () => {
    if (params.id) {
      setLoading(true);
      getAllCardsForUser(params.id, "all").then((cards) => {
        setCards(cards);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (params.id) {
      setCards(state?.card_statuses);
      // fetchCardList();
    }
  }, []);

  const onArchiveCard = () => {
    setSubmitting(true);
    if (!params.id) return;
    archiveCard(selectedCard, params.id)
      .then(() => {
        dispatch(
          showToast({
            type: "success",
            title: "The card has been archived!",
          })
        );
        setSubmitting(false);
        setShowModal(false);
        fetchCardList();
      })
      .catch((err: AxiosError<any>) => {
        if (err.response?.status !== 401) {
          dispatch(
            showToast({
              type: "error",
              title:
                err.response?.data.error?.cause?.response.details.description ??
                err.response?.data.error.message ??
                "Can not archive card",
            })
          );
          setSubmitting(false);
        }
      });
  };

  const columns = [
    {
      field: "masked_card_number",
      headerName: "Card number",
      width: 160,
      sortable: false,
    },
    {
      field: "type",
      sortable: false,
      width: 160,
      headerName: "Type",
      formatter: (p: any) => <Box textTransform="capitalize">{p.type}</Box>,
    },
    {
      field: "status",
      sortable: false,
      width: 160,
      headerName: "Status",
    },
    {
      field: "created_at",
      sortable: false,
      width: 160,
      headerName: "Date created",
      formatter: (p: any) => {
        if (p?.created_at) {
          return <UTCLocalTimeStampCompact date={p.created_at} />;
        }
        return <>-</>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      sortable: false,
      formatter: (p: any) => {
        const status = p.status.toLowerCase();
        return (
          <Stack direction="row" spacing={0}>
            <PrintCardButton
              cards={[p]}
              walletHoldersName={`${state?.first_name} ${state?.last_name}`}
            />
            {status !== CARD_STATUS.archived.code && (
              <Typography
                sx={{ mr: "4px", ml: "4px" }}
                fontSize="12px"
                fontWeight={400}
                color={colors.Neutral800}
              >
                {"|"}
              </Typography>
            )}
            {status !== CARD_STATUS.archived.code && (
              <Button
                sx={{ m: 0, p: 0, minWidth: 0 }}
                variant="text"
                size="small"
                onClick={() => {
                  setShowModal(true);
                  setSelectedCard(p.id);
                }}
              >
                <Typography
                  sx={{ textDecoration: "underline" }}
                  fontSize="12px"
                  fontWeight={400}
                  color={colors.Primary600}
                >
                  {"Archive"}
                </Typography>
              </Button>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Box sx={style}>
          <CloseIcon
            onClick={() => setShowModal(false)}
            sx={{ cursor: "pointer" }}
          />
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <DangerIcon />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ my: 2 }}
            >
              Archive Card
            </Typography>
            <Typography id="modal-modal-description" sx={{ display: "inline" }}>
              Archiving this card will automatically activate it if its
              currently inactive, and then attempt to archive it. Are you sure
              you want to proceed?
            </Typography>

            <Box display="flex" justifyContent="space-between" mt={3} mb={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={onArchiveCard}
                disabled={submitting}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  border: "1px solid #BEADFF",
                  color: "#381C9F",
                  borderRadius: "100px",
                  fontSize: "14px",
                  padding: "10px 20px",
                  textAlign: "center",
                  display: "inline-block",
                  ml: 2,
                  cursor: "pointer",
                  "&:hover": {
                    background: "rgba(93, 49, 255, 0.04)",
                  },
                }}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {params.id && <WhDetailHeader id={params.id} />}
      <Card>
        <CardContent sx={{ margin: ["10px", "10px", "20px"] }}>
          {params.id && <WalletHolderTabMenu id={params.id} />}
          <Box>
            <TableContainer sx={{ marginTop: 4 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column: IColumnProps) => (
                      <TableCell key={column.field}>
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {cards.length === 0 && !loading && (
                    <TableRow>
                      <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                        No Card
                      </TableCell>
                    </TableRow>
                  )}
                  {cards.map((row: any) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.field];
                        return (
                          <TableCell key={column.field}>
                            {column.formatter ? column.formatter(row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default WalletHolderCardList;
